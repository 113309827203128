import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../Layout";
import lenses from "../imgs/lenses.webm";

function Credits() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="leave-top_margin">
      <Layout>
        <div className="about_main">
          <div className="about_left">
            <h1 style={{ fontSize: "48px" }}>About Us</h1>
          </div>
          <div className="about_right">
            <h4 className="about_right_h">
              Revolutionizing Live Streams with Interactive{" "}
              <b className="highlighted">AR</b> and{" "}
              <b className="highlighted">3D</b>
            </h4>
            <p className="about_right_p">
              <b className="highlighted">Streamfog</b> is an innovative app that
              gives streamers the opportunity to include Augmented Reality (AR)
              into their live broadcasts.
              <br />
              Our platform adds a new level of excitement and engagement for
              viewers with its unique 3D notifications that interact with the
              streamer's surroundings.
            </p>

            <div className="feature_video-wrapper">
              <video
                autoPlay="autoplay"
                loop
                muted
                controls={window.innerWidth < 767 ? "controls" : false}
                playsInline
                preload="auto"
                className="feature-video green-shadow"
                width="100%"
                webkit-playsinline="true"
                x-webkit-airplay="true"
              >
                <source src={lenses} type="video/webm" />
              </video>
            </div>

            {/* <Link to={"https://app.streamfog.com/"} className="cta_btn">
              <span>Reach 3D artist</span>
            </Link> */}
            <Link to={"mailto:kev@streamfog.com"} className="cta_btn_about">
              <span>Contact Us</span>
            </Link>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default Credits;
