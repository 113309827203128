import React, { useEffect, useState, useRef } from "react";
import { Calendar, ChevronRight, Mail } from "lucide-react";
import { LinkedIn } from "@mui/icons-material";
// import "../Fundraising.css";

const cards = [
  {
    src: "/images/clients.png",
    title: "Clients",
    description:
      "These global powerhouses have trusted us with their campaigns, validating our unique approach.",
  },
  {
    src: "/images/traction.png",
    title: "Techstars Traction",
    description:
      "During Techstars alone, we doubled our weekly streamer registrations, tripled B2B clients, and secured a strategic partnership with Snap Inc., exposing us to their ecosystem and investment opportunities.",
  },
  {
    src: "/images/transition.png",
    title: "Scaling up",
    description:
      "Right now streamers use our AR tool for free, while brands pay $5-10k per project. We are building both parts of a two-sided marketplace worth millions of dollars.",
  },
  {
    src: "/images/roadmap.png",
    title: "Roadmap",
    description:
      "Today we are working with influencers on Twitch - the perfect proving ground. Tomorrow, we'll expand to YouTube. But fixing the problem directly with platforms is the future.",
  },
];

const isSafari = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes('safari') && !userAgent.includes('chrome');
};

const InvestorLanding = () => {
  const videoRefBack = useRef(null);
  const videoRefFront = useRef(null);
  const fundraisingRef = useRef(null);
  const [email, setEmail] = useState("");
  const [activeImage, setActiveImage] = useState(null);
  const [isSafariBrowser, setIsSafariBrowser] = useState(false);

  const companyInfo = {
    name: "Streamfog",
    tagline:
      "We are revolutionizing advertising for streaming platforms. From disrupting mid- & pre-rolls to seamless placements using Augmented Reality.",
    calendarLink: "https://calendar.app.google/dvNGohBS4sgwMfEM6",
    founderName: "Kevin Bondzio",
    founderEmail: "kev@streamfog.com",
    linkedIn: "https://www.linkedin.com/in/kevin-bondzio/",
    profilePicture: "/images/founder-kev.jpg",
  };

  useEffect(() => {
    setIsSafariBrowser(isSafari());
    import("../Fundraising.css");
    setTimeout(() => {
      if (fundraisingRef.current) fundraisingRef.current.style.opacity = 1;
    }, 500);
  }, []);

  const handleImageClick = (src) => {
    setActiveImage(src);
  };

  const handleClose = () => {
    setActiveImage(null);
  };

  useEffect(() => {
    const videoBack = videoRefBack.current;
    const videoFront = videoRefFront.current;

    const handleEnded = () => {
      // When video ends, wait for specified delay before playing again
      setTimeout(() => {
        if (videoBack) {
          videoBack.currentTime = 0;
          videoBack.play();
          videoFront.currentTime = 0;
          videoFront.play();
        }
      }, 5000);
    };

    if (videoBack) {
      videoBack.addEventListener("ended", handleEnded);
    }

    // Cleanup listener when component unmounts
    return () => {
      if (videoBack) {
        videoBack.removeEventListener("ended", handleEnded);
      }
    };
  }, []);

  return (
    <div
      className="fundraising-wrapper"
      ref={fundraisingRef}
      style={{ opacity: 0 }}
    >
      <div className="relative pb-12 px-4">
        <div className="max-w-4xl mx-auto space-y-8">
          {/* Header Section */}
          <div className="text-center space-y-6">
            {/* Video and Title Container with 16:9 aspect ratio */}
            <div className="relative w-full mx-auto">
              {/* 16:9 aspect ratio container */}
              <div className="relative w-full pb-[56.25%]">
                {/* Video absolute positioned to fill container */}
                {!isSafariBrowser && (
                  <div className="absolute inset-0 w-full h-full">
                    <video
                      ref={videoRefBack}
                      autoPlay
                      muted
                      playsInline
                      className="w-full h-full object-cover"
                    >
                      <source
                        src="/images/background.webm"
                        type="video/webm"
                      />
                    </video>
                  </div>
                )}
                {/* Title absolute positioned to fill container */}
                <div className="absolute inset-0 flex items-center justify-center">
                  <img src="images/streamfog-logo.svg" alt="" />
                </div>
                {!isSafariBrowser && (
                  <div className="absolute inset-0 w-full h-full">
                    <video
                      ref={videoRefBack}
                      autoPlay
                      muted
                      playsInline
                      className="w-full h-full object-cover"
                    >
                      <source
                        src="/images/foreground.webm"
                        type="video/webm"
                      />
                    </video>
                  </div>
                )}
              </div>
            </div>
            <p className="text-xl leading-normal">{companyInfo.tagline}</p>
          </div>

          {/* Rest of the component remains the same */}
          {/* Accelerator Badges */}
          <div className="flex flex-wrap justify-center items-center gap-8 py-6">
            <div className="flex items-center space-x-3 card-background p-3 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
              <img
                src="/images/techstars.png"
                alt="Techstars"
                className="w-16 h-16 rounded-lg"
              />
              <div>
                <p className="font-black mb-1 text-main">Techstars '24</p>
                <p className="text-sm font-thin">Portfolio Company</p>
              </div>
            </div>
            <div className="flex items-center space-x-3 card-background p-3 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
              <img
                src="/images/nvidia.jpeg"
                alt="NVIDIA Inception"
                className="w-16 h-16 rounded-lg"
              />
              <div>
                <p className="font-black mb-1 text-main">NVIDIA Inception</p>
                <p className="text-sm font-thin">Program Member</p>
              </div>
            </div>
          </div>

          {/* Investment Highlights + CTA Section */}
          <div className="grid md:grid-cols-2 gap-8">
            {/* Investment Highlights */}
            <div className="overflow-hidden backdrop-blur-sm border-2 border-transparent border-main rounded-2xl">
              <div className="p-4">
                <h2 className="text-xl font-semibold mb-3">
                  About us
                </h2>
                <div className="space-y-4">
                  <div className="flex items-center space-x-2">
                    <ChevronRight className="text-main" size={16} />
                    <p className="text-m">
                      20% MoM B2C growth
                    </p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <ChevronRight className="text-main" size={16} />
                    <p className="text-m">
                      over 15 successful campaings on Twitch
                    </p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <ChevronRight className="text-main" size={16} />
                    <p className="text-m">
                      $60,000 revenue in 2024
                    </p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <ChevronRight className="text-main" size={16} />
                    <p className="text-m">
                      Strategic funding round planned for Q1 2024
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Updated CTA Section */}
            <div className="space-y-6 mt-1">
              {/* Newsletter Signup */}
              <div className="space-y-4">
                <form
                  action="https://streamfog.us14.list-manage.com/subscribe/post?u=7c2e3e93ddc0422dd6e3f7117&amp;id=19945306cc&amp;f_id=00ede8e0f0"
                  method="post"
                  className="space-y-4"
                >
                  <div className="flex flex-col items-center gap-3">
                    <input
                      type="email"
                      name="EMAIL"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      required
                      className="w-full px-4 py-3 mb-1 rounded-lg border border-gray-300 text-black focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                    <input type="hidden" name="tags" value="11237191" />
                    <button
                      type="submit"
                      className="w-full inline-flex items-center justify-center px-8 py-3 theme-button rounded-xl 
                             hover:shadow-lg hover:scale-105 transition-all duration-300"
                    >
                      <Mail className="mr-2" size={20} />
                      Sign up for Investment Updates
                    </button>
                  </div>
                </form>
                {/* {showSuccess && (
                  <Alert className="bg-green-50 border-green-200">
                    <AlertDescription className="text-green-800">
                      Thank you for subscribing to our updates!
                    </AlertDescription>
                  </Alert>
                )} */}
              </div>

              {/* Divider with OR */}
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 body-background">OR</span>
                </div>
              </div>

              {/* Calendar Link */}
              <div className="space-y-4">
                <a
                  href={companyInfo.calendarLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full inline-flex items-center justify-center px-8 py-3 theme-button rounded-xl 
                         hover:shadow-lg hover:scale-105 transition-all duration-300"
                >
                  <Calendar className="mr-2" size={20} />
                  Schedule Meeting
                </a>
                <div className="flex items-center justify-center w-100">
                  <img
                    src={companyInfo.profilePicture}
                    alt=""
                    className="w-16 h-16 rounded-full"
                  />
                  <div className="ml-4">
                    <p className="font-black text-lg">
                      {companyInfo.founderName}
                    </p>
                    <p className="text-xs">Founder | CEO</p>
                    <a
                      href={`mailto:${companyInfo.founderEmail}`}
                      className="text-sm font-thin tracking-wider d-block"
                    >
                      {companyInfo.founderEmail}
                    </a>
                  </div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={companyInfo.linkedIn}
                    className="text-sm font-thin tracking-wider ml-2"
                  >
                    <LinkedIn sx={{ fontSize: 70 }} />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Metrics Cards */}
          <div className="grid md:grid-cols-2 gap-8 mt-8">
            {cards.map((card, index) => (
              <div
                key={index}
                className="overflow-hidden border-0 rounded-2xl card-background hover:shadow-xl transition-shadow backdrop-blur-sm"
              >
                <div className="p-6 space-y-4">
                  <img
                    src={card.src}
                    alt={card.title}
                    className="w-full rounded-lg hover:scale-[1.02] transition-transform cursor-pointer"
                    onClick={() => handleImageClick(card.src)}
                  />
                  <h3 className="font-black text-lg text-main">{card.title}</h3>
                  <p>{card.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Popup */}
      {activeImage && (
        <div
          className="fixed inset-0 bg-black-600 flex items-center justify-center z-50 backdrop-blur-2xl"
          onClick={handleClose}
        >
          <div className="relative">
            <img
              src={activeImage}
              alt="Popup"
              className="rounded-lg max-w-[90vw] max-h-[90vh]"
              onClick={(e) => e.stopPropagation()}
            />
            <button
              className="absolute top-2 right-3 text-white text-2xl"
              onClick={handleClose}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvestorLanding;
